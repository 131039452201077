import { Controller } from "stimulus"
import Sortable from "sortablejs/Sortable"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: this.element.getAttribute('data-sortable-handle'),
      animation: 150
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = null
  }
}