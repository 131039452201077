/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import ActionCable from 'actioncable'
ActionCable.startDebugging()
window.Cable = ActionCable.createConsumer()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import 'trix'
import 'trix/dist/trix.css'

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

if (window.dataConfirmModal) {
  dataConfirmModal.setDefaults({
    zIndex: 10099
  })
}
