import { Controller } from "stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  connect() {
    $(this.element).on('ajax:before', this.beforeAjax.bind(this))
    $(this.element).on('ajax:success', this.handleAjax.bind(this))
    $(this.element).on('ajax:error', this.showError.bind(this))
  }

  disconnect() {
    $(this.element).off('ajax:before', this.beforeAjax)
    $(this.element).off('ajax:success', this.handleAjax)
    $(this.element).off('ajax:error', this.showError)
  }

  beforeAjax(e) {
    let isAdmin = this.data.get('isAdminValue') === "true"
    let text;
    if(isAdmin){
      text = 'Your report is generating, and you will receive an email notification when it is complete. You may now exit this screen.'
    } else {
      text = 'Generating your report...'
    } 
    Swal.fire({
      title: 'Processing!',
      text: text,
      type: 'info',
      showConfirmButton: isAdmin
    })
    Swal.showLoading()
  }

  handleAjax(e, data, status, xhr) {
    const subscription = Cable.subscriptions.create({ channel: "DownloadsChannel", download_id: data.download_id }, {
      received: (data) => {
        Swal.hideLoading()
        if (data.error) {
          this.showError()
        } else {
          Swal.fire({
            title: 'Finished!',
            text: 'Your download is ready',
            type: 'success',
            showConfirmButton: true,
            confirmButtonText: "Download"
          }).then(() => {
            if (typeof Trestle != 'undefined') {
              Trestle.visit(data.url)
            } else {
              Turbolinks.visit(data.url)
            }
          })
        }

        subscription.unsubscribe()
      }
    })
  }

  showError() {
    Swal.hideLoading()
    Swal.update({
      title: "Error!",
      text: "There was an issue generating your download",
      type: "error",
      showConfirmButton: true
    })
  }
}