import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["trigger", "show"]

  toggle() {
    if (this.triggerTarget.value === this.element.dataset.toggleVisibilityOtherValue) {
      this.showTarget.classList.add("d-block")
      this.showTarget.classList.remove("d-none")
    } else {
      this.showTarget.classList.remove("d-block")
      this.showTarget.classList.add("d-none")
    }
  }
}