import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).children('.list-display').show()
    $(this.element).children('.grid-display').hide()
  }

  toggle() {
    var $cardList = $(this.element).siblings('.iris-card-list')
    if ($cardList.hasClass('column')) {
      $cardList.removeClass('column')
      $(this.element).children('.list-display').show()
      $(this.element).children('.grid-display').hide()
    } else {
      $cardList.addClass('column')
      $(this.element).children('.list-display').hide()
      $(this.element).children('.grid-display').show()
    }
  }
}
