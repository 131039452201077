import { Controller } from "stimulus"

export default class extends Controller {
  static hiddenFieldFormat = 'yy-mm-dd'
  static userFacingFieldFormat = 'mm-dd-yy'

  connect() {
    let $this = $(this.element)
    // Set up a hidden field to actually submit our data
    let $hiddenField = $('<input type="hidden" />')
    $hiddenField.attr('name', $this.attr('name'))
    if ($this.attr('value')) {
      let currentDate = $.datepicker.parseDate(this.constructor.hiddenFieldFormat, $this.attr('value'))
      $hiddenField.attr('value', $this.attr('value'))
      $this.attr('value', $.datepicker.formatDate(this.constructor.userFacingFieldFormat, currentDate))
    }

    $this.attr('name', '') // Reset the name attribute on the visible field so we don't get a double submission
    $hiddenField.insertBefore($this)

    $this.datepicker({
      dateFormat: this.constructor.userFacingFieldFormat,
      altField: $hiddenField,
      altFormat: this.constructor.hiddenFieldFormat
    })
  }
}
