import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "conditionalQuestionsWrapper", "mainQuestions", "question" ]
  connect() {
    this.questionTargets.forEach(item => {
      item.style.display = "none"
    })
    this.conditionalQuestionsWrapperTarget.style.display = "none"
    this.attachChangeListeners("input", this.toggleCheckboxConditionals)
    this.attachChangeListeners("select", this.toggleSelectConditionals)
    this.determineWrapperDisplay()
  }

  attachChangeListeners(selectors, listener) {
    let list = this.mainQuestionsTarget.querySelectorAll(selectors)
    list.forEach(item => {
      listener.call(this, list)
      item.addEventListener("change", event => {
        listener.call(this, list)
        this.determineWrapperDisplay()
      })
    })
  }

  setShowing(element, showing) {
    if (showing) {
      element.style.display = "block"
      element.querySelector(".destroy-input").value = "0"
    } else {
      element.style.display = "none"
      element.querySelector(".destroy-input").value = "1"
    }
  }

  toggleCheckboxConditionals(targets) {
    let targetsList = Array.from(targets)
    let selectedTargets = targetsList.filter(target=>{return target.checked})
    let choiceIds = selectedTargets.map((element) => parseInt(element.dataset.choiceId))
    this.questionTargets.forEach(item => {
      let intersect = JSON.parse(item.dataset.parentChoiceIds).filter(x => choiceIds.includes(x))
      this.setShowing(item, intersect.length !== 0)
    })
  }

  toggleSelectConditionals(targets) {
    let targetsList = Array.from(targets)
    let choiceIds = targetsList.map((element) => parseInt(element.selectedOptions[0].dataset.choiceId))
    this.questionTargets.forEach(item => {
      let intersect =  JSON.parse(item.dataset.parentChoiceIds).filter(x => choiceIds.includes(x))
      this.setShowing(item, intersect.length !== 0)
    })
  }

  determineWrapperDisplay() {
    this.conditionalQuestionsWrapperTarget.style.display = this.questionTargets.some(x => getComputedStyle(x).display === "block") ? "block" : "none"
  }
}